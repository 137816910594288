import Leaderboard from "./Leaderboard";
import NavBar from "./NavBar";

export default function LeaderboardFull() {
  return (
    <>
      <NavBar />
      {/* <div style={{padding : "1rem", height: "100%", backgroundColor: "#FFE500"}}> */}
        <Leaderboard />
      {/* </div> */}
    </>
  );
}
