import { useEffect, useState } from "react";
import style from "../style/Leaderboard.module.css";
import pointsIcon from "../Icons/Points_Icon_9x8.svg";
import ghostPicture from "../leaderboard_assets/ghost picture.svg";
import cryingGhostPicture from "../leaderboard_assets/sad ghost picture.svg";

export default function Leaderboard() {
  const currentDate = new Date();
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const suffix = getDaySuffix(lastDayOfMonth);
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), lastDayOfMonth)
    .toLocaleDateString("en-US", { month: "long", day: "numeric" }) + suffix + ", " + currentDate.getFullYear();

  const [error, setError] = useState(false);
  const [topScores, setTopScores] = useState([]);
  const [nearbyScores, setNearbyScores] = useState([]);

  const id = JSON.parse(localStorage.getItem("recurringUser")).id;

  function getDaySuffix(day) {
    if (day.toString().endsWith("1")) {
      return "st";
    } else {
      return "th";
    }
  }

  useEffect(() => {
    fetch(`${window.apiUrl}/leaderboard?playerId=${id}`)
      .then((res) => res.json())
      .then((data) => {
        setTopScores(data.topScores);
        setNearbyScores(data.nearbyScores);
      })
      .catch((err) => {
        console.error('Error fetching leaderboard:', err);
        setError(true);
      });
  }, [id]);

  console.log(error)


  if (error) {
    return (
      <>
        <div className={style.globalContainer}>
          <div className={style.headingContainer}>
            <h2>Leaderboard</h2>
            <p>Competition ends on: <span className={style.underlinedText}>{endDate}</span></p>
          </div>
          {/* display the "ghost picture.svg" inside the ./leaderboard_assets directory */}
          <div className={style.ghostContainer}>
            <img src={cryingGhostPicture} alt="crying ghost" />
            <h2>Leaderboard could<br /> not be loaded</h2>
          </div>
        </div>
      </>
    )
  }
  else if (topScores.length === 0 && nearbyScores.length === 0) {
    return (
      <>
        <div className={style.globalContainer}>
          <div className={style.headingContainer}>
            <h2>Leaderboard</h2>
            <p>Competition ends on: <span className={style.underlinedText}>{endDate}</span></p>
          </div>
          {/* display the "ghost picture.svg" inside the ./leaderboard_assets directory */}
          <div className={style.ghostContainer}>
            <img src={ghostPicture} alt="ghost" />
            <h2>The Leaderboard <br /> is Empty</h2>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={style.globalContainer}>
          <div className={style.topContainer}>
            <div className={style.heading}>
              <h2>Leaderboard</h2>
              <p>Competition ends on: <span className={style.boldText}>{endDate}</span></p>
            </div>
            <div className={style.topContainerRank2}>
              {topScores
                .slice(1, 2)
                .map((topScore, idx) => {
                  return (
                    <>
                      <p className={style.top2Icon}>2</p>
                      <div key={idx} className={style.leaderboardEntryTop2}>
                        <img
                          src={topScore.picture}
                          alt="profile"
                          className={style.profileImgTop2}
                        ></img>
                        <p className={style.usernameTop2}>
                          {topScore.user}
                        </p>
                        <div className={style.pointsContainer}>
                          <p className={style.pointsTop2}>
                            {topScore.total}
                          </p>
                          <img
                            src={pointsIcon}
                            className={style.pointsIcon}
                            alt="points"
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}

              {topScores
                .slice(0, 1)
                .map((topScore, idx) => {
                  return (
                    <>
                      <p className={style.top1Icon}>1</p>
                      <div key={idx} className={style.leaderboardEntryTop1}>
                        <img
                          src={topScore.picture}
                          alt="profile"
                          className={style.profileImgTop1}
                        ></img>
                        <p className={style.usernameTop1}>
                          {topScore.user}
                        </p>
                        <div className={style.pointsContainer}>
                          <p className={style.pointsTop1}>
                            {topScore.total}
                          </p>
                          <img
                            src={pointsIcon}
                            className={style.pointsIcon}
                            alt="points"
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}

              {topScores
                .slice(2, 3)
                .map((topScore, idx) => {
                  return (
                    <>
                      <p className={style.top3Icon}>3</p>
                      <div key={idx} className={style.leaderboardEntryTop2}>
                        <img
                          src={topScore.picture}
                          alt="profile"
                          className={style.profileImgTop2}
                        ></img>
                        <p className={style.usernameTop2}>
                          {topScore.user}
                        </p>
                        <div className={style.pointsContainer}>
                          <p className={style.pointsTop2}>
                            {topScore.total}
                          </p>
                          <img
                            src={pointsIcon}
                            className={style.pointsIcon}
                            alt="points"
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}

            </div>
          </div>
          <div className={style.bottomContainer}>
            <div className={style.bottomContainerInner}>
              {nearbyScores
                .map((leaderboard, idx) => {
                  if (leaderboard.isCurrentUser) {
                    return (
                      <div key={idx}>
                        <div className={style.leaderboardEntry}>
                          <div className={style.avatar}>
                            <p className={style.placementOWN}>{idx + 4}</p>
                            <img
                              src={leaderboard.picture}
                              alt="profile"
                              className={style.profileImg}
                            ></img>
                            <p className={style.username}>
                              {leaderboard.user}
                            </p>
                          </div>
                          <div className={style.pointsContainer}>
                            <p className={style.points}>{leaderboard.total}</p>
                            <img
                              src={pointsIcon}
                              className={style.pointsIcon}
                              alt="points"
                            ></img>
                          </div>
                        </div>
                        <hr className={style.hr}></hr>
                      </div>
                    );
                  } else {
                    return (
                      <div key={idx}>
                        <div className={style.leaderboardEntry}>
                          <div className={style.avatar}>
                            <p className={style.placement}>{idx + 4}</p>
                            <img
                              src={leaderboard.picture}
                              alt="profile"
                              className={style.profileImg}
                            ></img>
                            <p className={style.username}>
                              {leaderboard.user}
                            </p>
                          </div>
                          <div className={style.pointsContainer}>
                            <p className={style.points}>{leaderboard.total}</p>
                            <img
                              src={pointsIcon}
                              className={style.pointsIcon}
                              alt="points"
                            ></img>
                          </div>
                        </div>
                        <hr className={style.hr}></hr>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </>
    );
  }
}