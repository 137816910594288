import React from "react";
import startAnimation from "../Onboarding_Assets/Onbarding_Intro_Animation_v1.gif";
import styles from "../style/start.module.css"

function Start() {

  return (
    <div>
      <img className={styles.startAnimation} src={startAnimation} alt="animation"></img>
    </div>
  );
}
export default Start;
