import { Modal } from "react-bootstrap";
import helperIcon from "../QR-scan_assets/Bier_Etikette_Assetsaufbereitet_v1_Scanner_helper_Icon.svg";
import close from "../QR-scan_assets/Bier_Etikette_Assetsaufbereitet_v1_Close_Icon.svg";
import { useEffect, useState } from "react";

function Popup() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <img
            src={close}
            onClick={handleClose}
            alt=""
            className="closeImg"
          ></img>
        </Modal.Header>
        <Modal.Body>
          <p className="popUptxt">
            Scan the QR code of a beer bottle to continue
          </p>
          <img src={helperIcon} alt="" className="helperIcon"></img>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default Popup;
