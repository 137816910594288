import { Card, Image } from "semantic-ui-react";

import { useEffect, useState } from "react";
import style from "../style/challange.module.css";
import { useParams } from "react-router-dom";
import SwipeButton from "./SwipeButton";
import { useTimer } from "react-timer-hook";
import Hourglass from "../Icons/Hourglass.svg";
import "../style/libMod.css";
import { useHistory } from "react-router-dom";
import ConfirmQR from "./ConfirmQR";

const CardSelected = (props) => {
  const styleLink = document.createElement("link");
  styleLink.rel = "stylesheet";
  styleLink.href =
    "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
  document.head.appendChild(styleLink);

  let { challengeId } = useParams();
  // console.log(challengeId);

  const [challenge, setChallenge] = useState([]);

  const [challengeResult, setChallengeResult] = useState([]);

  useEffect(() => {
    fetch(`${window.apiUrl}/challenges/${challengeId}`)
      .then((res) => res.json())
      .then((data) => { setChallenge(data); return data})
      .then((data) => {

        let playerID = JSON.parse(localStorage.getItem("recurringUser")).id;
    
        let body = JSON.stringify({
          player: {
            id: playerID,
          },
          challenge: {
            id: data.id,
          },
          Points: 1,
          Result: "Pending",
        });
    
        fetch(`${window.apiUrl}/challenge-results`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          body: body,
        })
          .then((response) => response.json())
          .then((data) => { setChallengeResult(data); return data; })
          .then((data) => console.log(data));

      })
  }, [challengeId]);

  let Ctime = challenge.DurationInMinutes * 60;

  const history = useHistory();

  function MyTimer({ expiryTimestamp }) {
    const { seconds, minutes } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        console.warn("onExpire called");
        history.push("/lost")

        history.push("/lost");
        let challengeResultId = challengeResult.id;
        let body = JSON.stringify({
          Result: "Lost",
        });
        fetch(`${window.apiUrl}/challenge-results/${challengeResultId}`, {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          body: body,
        })
          .then((response) => response.json())
          .then((data) => console.log(data));
      },
    });

    return (
      <>
        <span>{minutes}</span> : <span>{seconds}</span>
      </>
    );
  }

  const time = new Date();
  time.setSeconds(time.getSeconds() + Ctime);

  const [step, setStep] = useState(0);

  function pushWinner() {
    console.log("task done swiped");
    setStep(step + 1);
  }

  // let num = 12;

  function ChallengePass() {
    return (
      <div id={style.topChallengeSelected}>
        <div id={style.TopBox}>
          <p id={style.TopBoxText}>
            You still have <strong> <MyTimer expiryTimestamp={time} /> </strong> min to complete
            the challenge
          </p>
          <img id={style.Hourglass} src={Hourglass} alt="hourglass"></img>
        </div>
        <SwipeButton color="green" onSuccess={pushWinner} text="Task done" />
      </div>
    );
  }

  return (
    <Card style={{ width: 340 }}>
      <Card.Description style={{ height: 140 }}>
        {step === 0 && <ChallengePass />}
        {step === 1 && <ConfirmQR challengeResultId={challengeResult.id} />}
      </Card.Description>
      <Image src={challenge?.imageUrl} wrapped ui={false} />
      <Card.Content>
        <Card.Header>
          {challenge.Title} | {challenge.Difficulty}
        </Card.Header>
        <Card.Description style={{ height: 140 }}>
          {challenge.Description}

          <div id={style.BtnBox}>
            <button
              onClick={() => {
                history.push("/lost");
                let challengeResultId = challengeResult.id;
                let body = JSON.stringify({
                  Result: "Lost",
                });
                fetch(`${window.apiUrl}/challenge-results/${challengeResultId}`, {
                  method: "PUT",
                  headers: new Headers({
                    "Content-Type": "application/json",
                  }),
                  body: body,
                })
                  .then((response) => response.json())
                  .then((data) => console.log(data));
              }}
              id={style.acptBtnB}
            >
              Give up
            </button>
          </div>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default CardSelected;
