import Challenges from "./Challenges.js";
import style from "../style/challangeSelect.module.css"
import NavBar from "./NavBar";
import "../style/challangeSelectSub.css";

function ChallangeSelect() {

  return (
    <>
    <NavBar></NavBar>
    <div className={style.body}>
            <div id={style.background}></div>
            <div id={style.background2}></div>   
      <Challenges></Challenges>
   </div>
   </>
  );
}

export default ChallangeSelect;
