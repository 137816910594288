import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card } from "semantic-ui-react";
import style from "../style/Confirm.module.css";
import "../style/altLibMod.css";
import SwipeButton from "./SwipeButton";

function Confirm() {
  const history = useHistory();

  const styleLink = document.createElement("link");
  styleLink.rel = "stylesheet";
  styleLink.href =
    "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
  document.head.appendChild(styleLink);

  let { challengeResultId } = useParams();

  const [result, setResult] = useState([]);

  useEffect(() => {
    fetch(`${window.apiUrl}/challenge-results/${challengeResultId}`)
      .then((res) => res.json())
      .then((data) => setResult(data));
  }, [challengeResultId]);

  function handleSubmit() {
    history.push(`/confirmWin/${result.player.username}`);
    let challengeResultId = result.id; // challengeResult.id;
    let body = JSON.stringify({
      Result: "Won",
    });
    fetch(`${window.apiUrl}/challenge-results/${challengeResultId}`, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: body,
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
  }

  return (
    <div id={style.cardPlacement}>
      <div id={style.background}></div>
      <Card style={{ width: 340 }}>
        <Card.Description style={{ height: 140, backgroundColor: "#FFE700" }}>
          <div className={style.profileimgContainer}>
            <img
              className={style.profileimg}
              alt="profile img"
              src={result?.player?.profileimg}
            ></img>
          </div>
        </Card.Description>
        <Card.Content>
          <Card.Header>
            Did {result?.player?.username} pass the task:{" "}
            {result?.challenge?.Title} ?
          </Card.Header>
          <Card.Description style={{ height: "400px" }}>
            The challenge was: {result?.challenge?.Description}
            {/* <p>{challengeResultId}</p> */}
            <div id={style.BtnBox}>
              <SwipeButton
                className={style.button}
                color="yellow"
                onSuccess={handleSubmit}
                text="Passed challenge"
              />
              <button
                id={style.acptBtnB}
                onClick={() => {
                  history.push(`/ConfirmLoose/${result.player.username}`);
                  let challengeResultId = result.id; // challengeResult.id;
                  let body = JSON.stringify({
                    Result: "Lost",
                  });
                  fetch(
                    `${window.apiUrl}/challenge-results/${challengeResultId}`,
                    {
                      method: "PUT",
                      headers: new Headers({
                        "Content-Type": "application/json",
                      }),
                      body: body,
                    }
                  )
                    .then((response) => response.json())
                    .then((data) => console.log(data));
                }}
              >
                Failed challenge
              </button>
            </div>
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  );
}
export default Confirm;
