import styles from "../style/RulesW.module.css";
import becomeTheKing from "../Onboarding_Assets/Bier_Etikette_Assetsaufbereitet_v1_become_the_party_king_icon.svg";
import chooseWise from "../Onboarding_Assets/Bier_Etikette_Assetsaufbereitet_v1_choose_wise_icon.svg";
import masterTheChallenge from "../Onboarding_Assets/Bier_Etikette_Assetsaufbereitet_v1_master_the_challenge_icon.svg";
import passTheTask from "../Onboarding_Assets/Bier_Etikette_Assetsaufbereitet_v1_passed_the_task_icon.svg";

function RulesW() {
  function RuleCtrl() {
    var x = document.getElementById("rules");
    if (x.style.display === "none") {
      x.style.display = "inherit";
    } else {
      x.style.display = "none";
    }
  }
  return (
    <div className={styles.transparent}>
      <div className={styles.bgTop}>
        <div className={styles.ruleContainer}>
          <h2> Playing Rules</h2>
          <section className={styles.rules}>
            <img
              className={styles.iconSize}
              src={chooseWise}
              alt="choose wise"
            ></img>
            <div className={styles.textBox}>
              <h4 className={styles.ruleTitle}>Choose wise</h4>
              <p className={styles.ruleText}>
                Choose a challenge that you have to complete.
              </p>
            </div>
          </section>

          <section className={styles.rules}>
            <img
              className={styles.iconSize}
              src={masterTheChallenge}
              alt="master the challenge"
            ></img>
            <div className={styles.textBox}>
              <h4 className={styles.ruleTitle}>Master the challenge</h4>
              <p className={styles.ruleText}>If you fail you lose points.</p>
            </div>
          </section>

          <section className={styles.rules}>
            <img
              className={styles.iconSize}
              src={passTheTask}
              alt="pass the task"
            ></img>
            <div className={styles.textBox}>
              <h4 className={styles.ruleTitle}>Passed the task?</h4>
              <p className={styles.ruleText}>
                A third person confirms that you passed.
              </p>
            </div>
          </section>

          <section className={styles.rules}>
            <img
              className={styles.iconSize}
              src={becomeTheKing}
              alt="become the king"
            ></img>
            <div className={styles.textBox}>
              <h4 className={styles.ruleTitle}>Become the party king</h4>
              <p className={styles.ruleText}>
                The person with the most points in the Quartal wins.
              </p>
            </div>
          </section>
          <div id={styles.BtnBox}>
            <button onClick={RuleCtrl} id={styles.acptBtn}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RulesW;
