import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "../style/App.css";
import Home from "./home";
import Onboarding from "./onboarding";
import ChallangeSelect from "./challangeSelect";
import ChallengeConfirmationScreen from "./ChallengeConfirmationScreen";
import LooserPage from "./LooserPage";
import Confirm from "./Confirm";
import WinnerPage from "./WinnerPage";
import ConfirmWin from "./ConfirmWin";
import ConfirmLoose from "./ConfirmLoose";
import RuleContainer from "./RuleContainer";
import WTCulture from "./WTCulture";
import LeaderboardFull from "./LeaderboardFull";

function Landing() {
  const location = useLocation();
  console.log(location.pathname);

  if (
    !localStorage.getItem("recurringUser") &&
    !location.pathname.includes("/onboarding")
  ) {
    return <Redirect to="/onboarding" />;
  } else {
    return "";
  }
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/onboarding">
          <Onboarding />
        </Route>
        <Route exact path="/scan">
          <Home />
        </Route>
        <Route exact path="/">
          <ChallangeSelect />
        </Route>
        <Route path="/challengeConfirmation/:challengeId">
          <ChallengeConfirmationScreen />
        </Route>
        <Route path="/confirm/:challengeResultId">
          <Confirm />
        </Route>
        <Route exact path="/lost">
          <LooserPage />
        </Route>
        <Route exact path="/won">
          <WinnerPage />
        </Route>
        <Route exact path="/confirmWin/:userName">
          <ConfirmWin />
        </Route>
        <Route exact path="/confirmLoose/:userName">
          <ConfirmLoose />
        </Route>
        <Route exact path="/rules">
          <RuleContainer />
        </Route>
        <Route exact path="/culture">
          <WTCulture />
        </Route>
        <Route exact path="/leaderboard">
          <LeaderboardFull />
        </Route>
      </Switch>
      <Landing />
    </BrowserRouter>
  );
}

export default App;
