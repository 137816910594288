import style from "../style/ChallengeScreen.module.css";
import CardSelected from "./CardSelected";
import NavBar from "./NavBar";


function ChallengeConfirmationSrceen() {


  return (
    <>
      <NavBar color="green" />
      <div className={style.body}>
        <div id={style.background}></div>
        <CardSelected></CardSelected>
      </div>
    </>
  );
}

export default ChallengeConfirmationSrceen;
