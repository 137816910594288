import styles from "../style/onboardingTexts.module.css"

function RulesText() {
  return (
    <div>
      <h1 className={styles.h1Rules}>Those are the Playing rules</h1>
      <p className={styles.bodyRules}>
        The game always lasts quarterly, whoever has the most points at the end
        wins. Then the points are set to zero again.
      </p>
    </div>
  );
}

export default RulesText;
