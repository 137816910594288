import NavBar from "./NavBar";
import Scanner from "./scanner";
import { BrowserRouter as Router} from "react-router-dom";

const Home = (props) => {
  return (
    <Router>
      <>
        <NavBar/>
        <Scanner></Scanner>
      </>
    </Router>
  );
}
export default Home;
