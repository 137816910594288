import { useHistory, useParams } from "react-router-dom";
import { Card } from "semantic-ui-react";
import style from "../style/Confirm.module.css";
import SwipeButton from "./SwipeButton";
import "../style/altLibModConfirmer.css";
import Icon from '../Icons/Failed_Icon.svg';
import style3 from "../style/LooserPage.module.css";

function ConfirmWin() {
  const styleLink = document.createElement("link");
  styleLink.rel = "stylesheet";
  styleLink.href =
    "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
  document.head.appendChild(styleLink);

  let history = useHistory();

  function handleSubmit() {
    history.push("/scan")
  }

  let { userName } = useParams();

  return (
    <div id={style.cardPlacement}>
      <div id={style.background}></div>
      <Card style={{ width: 340 }}>
        <Card.Description style={{ height: 140, backgroundColor: "#FFE700" }}>
          <div id={style3.topChallengeSelected}>
            <div id={style3.TopBox}>
              <img id={style3.Hourglass} src={Icon} alt="Icon"></img>
            </div>
          </div>
        </Card.Description>
        <Card.Content>
          <Card.Header>The player {userName} did not finish the challenge.</Card.Header>
          <Card.Description style={{ height: "200px !important" }}>
            Do you want to solve a challenge yourself?
            <div id={style.BtnBoxB}>
              <SwipeButton
                className={style.button}
                color="yellow"
                onSuccess={handleSubmit}
                text="Get started"
              />
            </div>
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  );
}
export default ConfirmWin;
