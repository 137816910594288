import { Card } from "semantic-ui-react";
import style from "../style/ChallengeScreen.module.css";
import NavBar from "./NavBar";
import "../style/altLibMod.css";
import style2 from "../style/LooserPage.module.css";
import Icon from "../Icons/Win_icon.svg";
import Leaderboard from "./Leaderboard";

function LooserPage() {
  const styleLink = document.createElement("link");
  styleLink.rel = "stylesheet";
  styleLink.href =
    "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
  document.head.appendChild(styleLink);

  return (
    <>
      <NavBar color="green" />
      <div className={style.body}>
        <div id={style.background}></div>
        <Card style={{ width: 340 }}>
          <Card.Description style={{ height: 140, backgroundColor: "#FFE700" }}>
            <div id={style2.topChallengeSelected}>
              <div id={style2.TopBox}>
                <p id={style2.TopBoxText}>
                  <strong>Congratulations!</strong> <br></br> You have
                  successfully mastered the task.
                </p>
                <img id={style2.Hourglass} src={Icon} alt="hourglass"></img>
              </div>
            </div>
          </Card.Description>
          <Card.Content>
            <Leaderboard />
          </Card.Content>
        </Card>
      </div>
    </>
  );
}

export default LooserPage;
