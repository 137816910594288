import style from "../style/challange.module.css";
import { QRCode } from "react-qr-svg";
import { useHistory } from "react-router-dom";

export default function ConfirmQR(props) {
  let challengeConfirmID = props.challengeResultId;
  console.log(challengeConfirmID);

  const history = useHistory();

  // const [result, setResult] = useState([]);

  setInterval(() => {
    console.log("hello asdad");
    fetch(`${window.apiUrl}/challenge-results/${challengeConfirmID}`)
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .then((data) => {
        if (data.Result === "Won") {
          history.push("/won");
        } else if (data.Result === "Lost") {
          history.push("/lost");
        }
      });
  }, 15000);

  return (
    <div id={style.topChallengeSelected}>
      <div id={style.TopBox}>
        <p id={style.TopBoxTextB}>
          Another person has to scan the QRCode code to confirm that you have
          passed the challenge.
        </p>
        <QRCode
          bgColor="#ffe500"
          fgColor="#000"
          level="Q"
          style={{ width: 100 }}
          value={`${window.location.origin}/confirm/${challengeConfirmID}`}
        ></QRCode>
      </div>
    </div>
  );
}
