import React, { useState } from "react";
 import { useHistory } from "react-router-dom";
import buttonImg from "../Onboarding_Assets/Bier_Etikette_Assetsaufbereitet_v1-05.svg";
import style from "../style/startButton.module.css";
import Rules from "./rules";
import Login from "./login";
import Start from "./start";
import RulesText from "./rulesText";
import StartText from "./startText";
import SwipeButton from "./SwipeButton";

function Onboarding() {
  const history = useHistory();
  const [step, setStep] = useState(0);
  let lastStep = 2;
  let onNext = (e) => {
    console.log("clicked");
    setStep(step + 1);
    if (step === lastStep) {
      history.push("/scan");
    }
  };
  let onSkip = (e) => {
    setStep(2);
  };

  window.onpopstate = function(event) {
    console.log("Hello i did a postate event");
  };

  return (
    <div className={style.content}>
      {step === 0 && <Start></Start>}
      {step === 0 && <StartText></StartText>}
      {step === 0 && (
        <button
          className={style.buttonNext}
          id="startControll"
          onClick={onNext}
        >
          <img className={style.buttonImg} src={buttonImg} alt="swipebutton"></img>
        </button>
      )}
      {step === 0 && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={onSkip} className={style.skip}>
          <p>Skip</p>
        </a>
      )}

      {step === 1 && <Rules></Rules>}
      {step === 1 && <RulesText></RulesText>}
      {step === 1 && (
        <SwipeButton color="yellow" disable={true} onSuccess={onNext} text="Let's play" />
      )}
      {step === 2 && <Login onSubmit={onNext}/>}
    </div>
  );
}

export default Onboarding;
