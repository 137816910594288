import style from "../style/WTCulture.module.css"
import NavBar from "./NavBar";
import Pug from "../Images/Dogi_Grune_Krone.png";


function WTCulture() {
  return (
    <div id={style.background}>
      <NavBar />
      <h3 id={style.title}> Freshly brewed by the WT culture</h3>
      <p id={style.text}>
        Our culture includes an inspiring, respectful team spirit as well as
        excellent coffee, a huge selection of teas and soft drinks, and our
        traditional employee-designed agency beer. Of course, we always vote
        democratically for the winning design. We are real team players. And as
        such, we also like to challenge each other. Whether it's concentrated
        idea ping-pong, action-packed Nerf battles or the virtual Wunderman
        Thompson Party Challenge, which makes our own agency beer even more
        sparkling.
      </p>
      <img id={style.Image} src={Pug} alt="Pug"></img>
    </div>
  );
}

export default WTCulture;
