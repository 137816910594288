import yellowNav from "../style/navbar.module.css";
import greenNav from "../style/gnavbar.module.css";
import { Component } from "react";
import Burger from "react-css-burger";
import styling from "../style/burger.module.css";
import "../style/libMod.css"

export default class NavBar extends Component {
  state = {
    active: false,
  };
  render() {
    function NavCtrl() {
      var x = document.getElementById("nav");
      if (x.style.display !== "inherit") {
        x.style.display = "inherit";
      } else {
        x.style.display = "none";
      }
    }

    const style = this.props.color === "green" ? greenNav : yellowNav;
    let user =
      localStorage.getItem("recurringUser") != null
        ? JSON.parse(localStorage.getItem("recurringUser"))
        : {};
    return (
      <div>
        <div className={style.navContainer}>
          <div className={style.user}>
            <img
              className={style.profileImg}
              src={user?.profileimg}
              alt="profile avatar"
            ></img>
            <article className={style.textBox}>
              <p className={style.userName}>{user?.username}</p>
              {/* <p className={style.score}>0 Points</p> */}
            </article>
          </div>
          <Burger
          className={styling.burgerMenu}
          onClick={() => {
            this.setState({ active: !this.state.active });
            NavCtrl();
          }}
          active={this.state.active}
          burger="vortex"
          color="black"
          hoverOpacity={0.8}
          scale={0.8}
        />
        </div>
        
        <div className={style.navigation} id="nav">
          <ul className={style.links}>
            <li>
              <a href="/rules"> Playing Rules </a>
            </li>
            <li>
              <a href="/"> Challenges </a>
            </li>
            <li>
              <a href="/scan"> Scan </a>
            </li>
             <li>
              <a href="/leaderboard"> Leaderboards </a>
            </li>
            <li>
              <a href="/culture"> About WT </a>
            </li>
          </ul>
          <ul className={style.tos}>
            <li>
              <a href="https://www.wundermanthompson.ch/datenschutz">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://www.wundermanthompson.ch/agb"> Terms of use </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
