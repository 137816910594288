import style from "../style/challange.module.css";
import { Card, Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";


const ChallengeCard = (props) => {

  const styleLink = document.createElement("link");
  styleLink.rel = "stylesheet";
  styleLink.href =
    "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
  document.head.appendChild(styleLink);

  const history = useHistory();

  return (
    <Card>
          <Image src={props.challenge?.imageUrl} className={style.CardImg} wrapped ui={false} />
          <Card.Content>
            <Card.Header>{props.challenge?.Title} | {props.challenge?.Difficulty}</Card.Header>
            <Card.Description style={{height: 100}}>
            {props.challenge?.Description}
            
               <div id={style.BtnBox}>
                  <button id={style.acptBtn} onClick={() => { history.push(`/challengeConfirmation/${props.challenge?.id}`)}}>Accept the challenge</button> 
              </div> 
            </Card.Description>
          </Card.Content>
        </Card>
  );
}

export default ChallengeCard;
