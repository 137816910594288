import style from "../style/RulesContainer.module.css";
import Rules from "./rules";
import NavBar from "./NavBar";

function RuleContainer() {
  return (
    <div id={style.background}>
      <NavBar />
      <Rules></Rules>
    </div>
  );
}
export default RuleContainer;
