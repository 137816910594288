import styles from "../style/onboardingTexts.module.css"

function StartText() {
  return (
    <div>
      <h1 className={styles.h1Start}>Welcome to the WT Party Challenges</h1>
      <p className={styles.bodyStart}>
          Here you will find exciting and varied tasks that you can solve alone
          or in a group.
        </p>
    </div>
  );
}

export default StartText;