import style from "../style/login.module.css";
import SwipeButton from "./SwipeButton";
import { useState } from "react";

function Login({ onSubmit }) {
  const [userName, setUserName] = useState("");
  const handleSubmit = () => {
    // if (userName.length < 3) {
    //   window.alert("Your username has to have at least 2 characters");
    //   window.location.reload();
    //   return false;
    // }

    fetch(`${window.apiUrl}/players/loginOrSignUp`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ username: userName }),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("recurringUser", JSON.stringify(data));
      })
      .then(onSubmit);
  };
  return (
    <div className={style.content}>
      <p
        id={style.hint}
        style={{ visibility: userName.length >= 2 ? "visible" : "" }}
      >
        The best thing to do is to <br></br> enter your WT login name.
      </p>
      <p
        id={style.inputTitle}
        style={{ visibility: userName.length >= 1 ? "visible" : "" }}
      >
        Your Nickname
      </p>
      <input
        className={style.nicknameinput}
        type="text"
        placeholder="Your Nickname"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      ></input>
      <SwipeButton
        className={style.button}
        color="black"
        arrowColor={userName.length >= 2 ? "#FFE300" : null}
        disabled={!userName}
        onSuccess={handleSubmit}
        text="Here we go"
      />
    </div>
  );
}

export default Login;
