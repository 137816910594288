import React, { Component } from "react";
import QRScan from "qrscan";
import "../style/scanner.css";
import Popup from "./popup";
import "../style/libMod.css"
import style from "../style/scanner.module.css";

class Scanner extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", watching: true };
    this.onFind = this.onFind.bind(this);
  }

  

  onFind(value) {
    this.setState({ value, watching: true });
    window.location.replace(this.state.value);
  }
   

  render() {
    return (
      <div>
        <Popup></Popup>
        <div>
          <div className={style.layer}>
            <div className={style.scannerpointer}></div>
          </div>
          <QRScan style={{ width: "100%", height: "100%" }} onFind={this.onFind} />
        </div>
      </div>
    );
  }
}

export default Scanner;
