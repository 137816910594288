import { useEffect, useState } from "react";
import ChallengeCard from "./ChallengeCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import RulesW from "./RulesW";
import style from "../style/challange.module.css";
import "../style/libMod.css";

function Challenges() {
  const styleLink = document.createElement("link");
  styleLink.rel = "stylesheet";
  styleLink.href =
    "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
  document.head.appendChild(styleLink);

  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    fetch(`${window.apiUrl}/challenges/random`)
      .then((res) => res.json())
      .then((data) => {
        console.log("Received this: " + JSON.stringify(data));
        setChallenges(data);
      });
    // console.log(challenge)
  }, []);

  function RuleCtrl() {
    var x = document.getElementById("rules");
    if (x.style.display !== "inherit") {
      x.style.display = "inherit";
    } else {
      x.style.display = "none";
    }
  }

  return (
    <>
      <div id={style.something}>
        {!!challenges.length && (
          <Carousel
            width="375px"
            centerMode
            centerSlidePercentage={80}
            autoPlay={false}
            interval={1000000000}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
          >
            {challenges.map((challenge) => {
              return (
                <ChallengeCard
                  challenge={challenge}
                  key={challenge?.id}
                ></ChallengeCard>
              );
            })}
          </Carousel>
        )}
      </div>
      <div className={style.rules} id="rules">
        <RulesW />
      </div>
      <button className={style.btn} onClick={RuleCtrl}>
        Playing Rules
      </button>
    </>
  );
}

export default Challenges;
